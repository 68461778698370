import React from "react";
import { Link } from "gatsby";
import { GiFlowerPot } from "react-icons/gi";


const ServiceCardOne = ({ data }) => {
  const { title, text, iconName, url } = data;
  return (
    <div className="icon_box_2 text-center" style={{ height: "100%" }}>
      <h3>{title}</h3>
      <p>{text}</p>
      <div className="iconWrap">
        {/* <i className={iconName}></i> */}
        <i>
          <GiFlowerPot />
        </i>
      </div>
      {/* <Link to={url}>discover more</Link> */}
    </div>
  );
};

export default ServiceCardOne;
